/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import type { SpringValue } from '@react-spring/web';
import { animated } from '@react-spring/web';
import ArrowDownIcon from 'src/assets/ArrowDownIcon';
import QuestionMarkIconCircle from 'src/assets/QuestionMarkIconCircle';
import ReactTooltip from 'src/components/react-tooltip/ReactTooltip';

import TailwindSelect from 'src/components/select/select';

export const monthOptions = [
  { label: 'Select', value: '0' },
  { label: 'January', value: '01' },
  { label: 'February', value: '02' },
  { label: 'March', value: '03' },
  { label: 'April', value: '04' },
  { label: 'May', value: '05' },
  { label: 'June', value: '06' },
  { label: 'July', value: '07' },
  { label: 'August', value: '08' },
  { label: 'September', value: '09' },
  { label: 'October', value: '10' },
  { label: 'November', value: '11' },
  { label: 'December', value: '12' },
];
interface DayMonthYearFilterProps {
  styles?: { opacity: SpringValue<number>; display: SpringValue<string> };
  containerClassName?: string;
  type?: string;
  date?: {
    day?: string;
    month?: string;
    year?: string;
    approximateYear?: string;
  };
  onChangeDay: (e: any) => void;
  onChangeMonth: (e: any) => void;
  onChangeYear: (e: any) => void;
  onChangeApproxYear: (e: any) => void;
  monthDropdown?: boolean;
  disabled?: boolean;
  showIsInferredDrodpown?: boolean;
  showApproxYears?: boolean;
  minYear?: string;
}

export default function DayMonthYearFilter({
  styles,
  containerClassName,
  date,
  onChangeDay,
  onChangeMonth,
  onChangeYear,
  onChangeApproxYear,
  type = 'number',
  monthDropdown = false,
  disabled = false,
  showIsInferredDrodpown = false,
  showApproxYears = true,
  minYear,
}: DayMonthYearFilterProps) {
  return (
    <animated.div
      // @ts-ignore
      style={styles}
      className={containerClassName}
    >
      <div className="flex w-[55px] flex-col text-sm">
        <p className="text-sm">Day</p>
        <input
          type={type}
          disabled={(monthDropdown && !date?.month) || disabled}
          autoComplete={'false'}
          onChange={onChangeDay}
          onInput={(e) => {
            const value = (e.target as HTMLInputElement).value;
            if (value.length > 2) {
              (e.target as HTMLInputElement).value = value.slice(0, 2);
            }
          }}
          value={date?.day}
          maxLength={2}
          min={1}
          max={31}
          placeholder="DD"
          className={`mt-1 flex h-12 items-center justify-center  overflow-visible rounded-md border-DEFAULT border-gray-300 px-2 text-left text-xs font-medium shadow-none placeholder:overflow-visible placeholder:text-center placeholder:text-gray-400 focus:border-gray-400 focus:ring-1 focus:ring-gray-400 disabled:bg-gray-100 `}
          alt="Day of Birth"
        />
      </div>
      <div
        className={`flex flex-col ${
          monthDropdown ? 'w-36' : 'w-[55px]'
        } text-sm`}
      >
        {!monthDropdown && <p className="text-sm">Month</p>}
        {monthDropdown ? (
          <TailwindSelect
            label="Month"
            disabled={!date?.year || disabled}
            className="!font-normal !text-slateBlack"
            placeholder="Select"
            onChange={onChangeMonth}
            dropdownButoonClassNames={'h-12'}
            value={date?.month}
            options={monthOptions?.map((month) => month.label)}
          />
        ) : (
          <input
            type={type}
            autoComplete={'false'}
            onChange={onChangeMonth}
            value={date?.month}
            onInput={(e) => {
              const value = (e.target as HTMLInputElement).value;
              if (value.length > 2) {
                (e.target as HTMLInputElement).value = value.slice(0, 2);
              }
            }}
            placeholder="MM"
            maxLength={2}
            className="mt-1 flex h-12 items-center overflow-visible rounded-md border-DEFAULT border-gray-300 px-2 text-left text-xs font-medium shadow-none placeholder:overflow-visible placeholder:text-center placeholder:text-gray-400 focus:border-gray-400 focus:ring-1 focus:ring-gray-400"
            alt="Month of Birth"
          />
        )}
      </div>
      <div className="flex flex-col text-sm">
        <p className="text-sm">Year</p>
        <div className="flex items-center">
          <input
            type={type}
            maxLength={4}
            onInput={(e) => {
              const value = (e.target as HTMLInputElement).value;
              if (value.length > 4) {
                (e.target as HTMLInputElement).value = value.slice(0, 4);
              }
            }}
            disabled={disabled}
            autoComplete={'false'}
            placeholder="YYYY"
            onChange={onChangeYear}
            onBlur={(e) =>
              minYear && parseInt(e?.target?.value || '') < parseInt(minYear)
                ? onChangeYear({
                    ...(e || {}),
                    target: { value: minYear.toString() },
                  })
                : null
            }
            value={date?.year}
            className="mt-1 h-12 w-[72px] overflow-visible rounded-md border-DEFAULT border-gray-300 px-2 text-left  text-xs font-medium shadow-none placeholder:overflow-visible placeholder:text-center placeholder:text-gray-400 focus:border-gray-400 focus:ring-1 focus:ring-gray-400 disabled:bg-gray-100"
            alt="Year of Birth"
          />
        </div>
      </div>
      {showApproxYears && (
        <div className="relative">
          <div className="flex items-center text-sm text-gray-500">
            +/- <span className="hidden md:inline">years</span>
            {showIsInferredDrodpown && (
              <div className="flex items-center">
                <QuestionMarkIconCircle
                  className="ml-2 flex size-5 items-center"
                  id="+-years"
                  data-for="+-years"
                  data-tip={'2002 +/- 1 means between 2001 and 2003'}
                />
                <ReactTooltip
                  className="tooltip-opacity"
                  id="+-years"
                  type={'info'}
                  multiline={true}
                  effect={'solid'}
                  place="top"
                  backgroundColor={'#ECECEC'}
                  textColor={'#454545'}
                />
              </div>
            )}
          </div>
          <select
            disabled={Boolean(date?.month || date?.day) || disabled}
            onChange={onChangeApproxYear}
            value={date?.approximateYear}
            className="mt-1 h-12 w-[55px] rounded-md border-DEFAULT border-gray-300 bg-none px-3 text-left text-sm text-gray-400 shadow-none focus:border-gray-400 focus:ring-1 focus:ring-gray-400 disabled:bg-gray-100 disabled:text-slate-500"
          >
            {showIsInferredDrodpown ? (
              <>
                <option value="0">0</option>
                <option value="1">1</option>
              </>
            ) : (
              <>
                {' '}
                <option value="0">0</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
              </>
            )}
          </select>

          <ArrowDownIcon
            className={`absolute  ${
              showIsInferredDrodpown ? 'right-3 md:right-9' : 'md:right-3'
            } pointer-events-none bottom-1/4 size-2.5 text-gray-400`}
          />
        </div>
      )}
    </animated.div>
  );
}
